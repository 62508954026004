<template>
  <div class="plateNumber">
    <div class="wrap">
      <div class="d-flex font-18 px-16 fw-500 text_primary justify-space-between">
        <div @click="cancel()">取消</div>
        <div @click="submitFn()">确认</div>
      </div>
      <!-- 车牌号码输入框 -->
      <div class="num-box">
        <div :class="{ active: activeKeyWordIndex == 0, num0: true }" @click="clickFirstWrap()">
          <span>{{ formData.num0 }}</span>
        </div>
        <div :class="{ active: activeKeyWordIndex == 1, num1: true }" @click="clickKeyWordWrap(1)">
          <span>{{ formData.num1 }}</span>
        </div>
        <em class="spot"></em>
        <div :class="{ active: activeKeyWordIndex == 2, num1: true }" @click="clickKeyWordWrap(2)">
          <span>{{ formData.num2 }}</span>
        </div>
        <div :class="{ active: activeKeyWordIndex == 3, num1: true }" @click="clickKeyWordWrap(3)">
          <span>{{ formData.num3 }}</span>
        </div>
        <div :class="{ active: activeKeyWordIndex == 4, num1: true }" @click="clickKeyWordWrap(4)">
          <span>{{ formData.num4 }}</span>
        </div>
        <div :class="{ active: activeKeyWordIndex == 5, num1: true }" @click="clickKeyWordWrap(5)">
          <span>{{ formData.num5 }}</span>
        </div>
        <div :class="{ active: activeKeyWordIndex == 6, num1: true }" @click="clickKeyWordWrap(6)">
          <span>{{ formData.num6 }}</span>
        </div>
        <div
          v-if="formData.commonCard == '2'"
          :class="{ active: isActive == 7, num1: true }"
          @click="clickKeyWordWrap(7)"
        >
          <span>{{ formData.num7 }}</span>
        </div>
      </div>
      <div class="box-check px-16 py-8">
        <Checkbox @change="handleCommonCardChange" v-model="checked" style="float: right"
          >新能源车
          <template #icon>
            <Icon svgClass="icon-class" :name="checked ? 'xz-xy' : 'weixuan'" />
          </template>
        </Checkbox>
      </div>
    </div>
    <div class="keyboard-wrap" v-if="firstWrapStatus">
      <div class="keyboard">
        <Button v-for="(item, index) in abbPlate._1" :key="index" @click="selectFirstWord(item)">{{ item }}</Button>
      </div>
      <div class="keyboard">
        <Button v-for="(item, index) in abbPlate._2" :key="index" @click="selectFirstWord(item)">{{ item }}</Button>
      </div>
      <div class="keyboard">
        <Button v-for="(item, index) in abbPlate._4" :key="index" @click="selectFirstWord(item)">{{ item }}</Button>
      </div>
      <div class="keyboard justify-space-between">
        <div>
          <Button v-for="(item, index) in abbPlate._5" :key="index" @click="selectFirstWord(item)">{{ item }}</Button>
        </div>
        <div class="delete-btn" @click="deleteCurrentKey">
          <Icon name="back" svgClass="font-24" />
        </div>
      </div>
    </div>
    <div class="keyboard-wrap" v-if="keyBoardStatus">
      <div class="keyboard" v-if="activeKeyWordIndex !== 1">
        <Button v-for="(item, index) in allKeyWord._1" :key="index" @click="clickKeyBoard(item)">{{ item }}</Button>
      </div>
      <div class="keyboard">
        <Button v-for="(item, index) in allKeyWord._3" :key="index + 10" @click="clickKeyBoard(item)">
          {{ item }}
        </Button>
      </div>
      <div class="keyboard">
        <Button v-for="(item, index) in allKeyWord._4" :key="index + 20" @click="clickKeyBoard(item)">{{
          item
        }}</Button>
      </div>
      <div class="keyboard justify-space-between">
        <div>
          <Button v-for="(item, index) in allKeyWord._6" :key="index + 30" @click="clickKeyBoard(item)">{{
            item
          }}</Button>
          <template v-if="(activeKeyWordIndex === 6 && formData.commonCard == '1') || activeKeyWordIndex === 7">
            <Button v-for="(item, index) in allKeyWord._7" :key="index + 40" @click="clickKeyBoard(item)">{{
              item
            }}</Button>
          </template>
        </div>
        <div class="delete-btn" @click="deleteCurrentKey">
          <Icon name="back" svgClass="font-24" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Checkbox } from 'vant';
export default {
  props: ['defaultValue', 'defaultType'],
  data() {
    return {
      checked: false,
      formData: {
        commonCard: '1',
        num0: '',
        num1: '',
        num2: '',
        num3: '',
        num4: '',
        num5: '',
        num6: '',
        num7: ''
      },
      abbPlate: {
        _1: ['京', '沪', '津', '渝', '鲁', '冀', '晋', '蒙', '辽', '吉'],
        _2: ['黑', '苏', '浙', '皖', '闽', '赣', '豫', '湘', '鄂', '粤'],
        _4: ['桂', '琼', '川', '贵', '云', '藏', '陕', '甘', '青', '宁'],
        _5: ['新']
      },
      allKeyWord: {
        _1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
        _3: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K'],
        _4: ['L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'],
        _6: ['W', 'X', 'Y', 'Z'],
        _7: [] // ['挂', '超']
      },
      activeKeyWordIndex: 0, // 当前车牌号
      keyBoardStatus: false,
      firstWrapStatus: false, // 选择弹窗
      confirmTitle: '',
      submitConfirm: false,
      submitConfirmFalse: false,
      submitConfirmText: '',
      isActive: 0
    };
  },
  components: {
    Button,
    Checkbox
  },
  mounted() {
    this.clickFirstWrap();
    this.formData.commonCard = this.defaultValue.length === 7 || this.defaultValue === '' ? '1' : '2';
    this.checked = this.defaultValue.length === 7 || this.defaultValue === '' ? false : true;
    this.defaultValue.split('').forEach((item, index) => {
      this.formData[`num${index}`] = item;
    });
    console.log(this.defaultType);
    if (this.defaultType != 'carPlate') {
      console.log(111);
      this.allKeyWord._7 = ['挂', '超'];
    }
  },
  watch: {
    defaultValue(n, o) {
      this.formData.commonCard = n.length === 7 || n === '' ? '1' : '2';
      this.checked = n.length === 7 || n === '' ? false : true;
      n.split('').forEach((item, index) => {
        this.formData[`num${index}`] = item;
      });
    }
  },
  methods: {
    clearInput() {
      this.formData = {
        commonCard: '1',
        num0: '',
        num1: '',
        num2: '',
        num3: '',
        num4: '',
        num5: '',
        num6: '',
        num7: ''
      };
      this.checked = false;
    },
    handleCommonCardChange(e) {
      // 同步修改车牌号类型
      this.formData.commonCard = e ? '2' : '1';
    },
    deleteCurrentKey() {
      if (this.activeKeyWordIndex > 0) {
        this.formData[`num${this.activeKeyWordIndex}`] = '';
        if (this.activeKeyWordIndex === 1) {
          this.firstClickStatus = true;
          this.firstWrapStatus = true;
          this.keyBoardStatus = false;
        }
        this.activeKeyWordIndex--;
        this.isActive--;
      }
    },
    clickFirstWrap() {
      // 点击第一个输入框
      this.firstClickStatus = true;
      this.firstWrapStatus = true;
      this.keyBoardStatus = false;
      this.formData.num0 = '';

      this.activeKeyWordIndex = 0;
    },
    selectFirstWord(value) {
      // 选择省份
      this.formData.num0 = value;
      this.firstSelectStatus = true;
      this.firstWrapStatus = false;
      this.firstClickStatus = false;
      this.keyBoardStatus = true;
      this.activeKeyWordIndex = 1;
    },
    clickKeyBoard(item) {
      // 点击自定义键盘
      this.formData['num' + this.activeKeyWordIndex] = item;

      if (this.formData.commonCard === '1') {
        this.activeKeyWordIndex++;
        if (this.activeKeyWordIndex > 6) {
          this.keyBoardStatus = false;
          this.submitFn();
        }
      } else {
        this.activeKeyWordIndex++;
        if (this.activeKeyWordIndex > 7) {
          this.keyBoardStatus = false;
          this.submitFn();
        }
      }
    },
    deleteWord() {
      // 退格
      // console.log(this.activeKeyWordIndex)
      // console.log(this.formData['num' + (this.activeKeyWordIndex - 1)])
      if (this.activeKeyWordIndex > 1) {
        this.formData['num' + (this.activeKeyWordIndex - 1)] = '';
        this.activeKeyWordIndex--;
      }
    },
    clickKeyWordWrap(activeKeyWordIndex) {
      this.keyBoardStatus = true;
      this.activeKeyWordIndex = activeKeyWordIndex;
      this.formData['num' + this.activeKeyWordIndex] = '';

      this.firstWrapStatus = false;

      this.isActive = activeKeyWordIndex;
    },
    cancel() {
      this.$emit('hide');
    },
    submitFn() {
      let plateLicense;
      if (this.formData.commonCard === '1') {
        plateLicense = this.plate_license_1;
        plateLicense = this.palindrome(plateLicense);
        if (plateLicense.length < 7) {
          // alert('请输入正确的车牌号')
          this.$toast('请输入正确的车牌号');
          return;
        }
      }
      if (this.formData.commonCard === '2') {
        plateLicense = this.plate_license_2;
        plateLicense = this.palindrome(plateLicense);
        if (plateLicense.length < 8) {
          // alert('请输入正确的车牌号')
          this.$toast('请输入正确的车牌号');
          return;
        }
      }
      this.$emit('getPlateLicense', plateLicense);
      this.$emit('hide');
      // alert(plateLicense)
    },
    palindrome(str) {
      var arr = str.split('');
      arr = arr.filter(function(val) {
        return (
          val !== ' ' &&
          val !== ',' &&
          val !== '.' &&
          val !== '?' &&
          val !== ':' &&
          val !== ';' &&
          val !== '`' &&
          val !== "'" &&
          val !== '_' &&
          val !== '/' &&
          val !== '-' &&
          val !== '\\' &&
          val !== '' &&
          val !== '(' &&
          val !== ')'
        );
      });
      return arr.join('');
    },
    checkIsHasSpecialStr(str) {
      var flag = false;
      var arr = str.split('');
      arr.forEach(val => {
        if (
          val === '!' ||
          val === '}' ||
          val === '{' ||
          val === ']' ||
          val === '[' ||
          val === '&' ||
          val === '$' ||
          val === '@' ||
          val === ' ' ||
          val === ',' ||
          val === '.' ||
          val === '?' ||
          val === ':' ||
          val === ';' ||
          val === '`' ||
          val === "'" ||
          val === '_' ||
          val === '/' ||
          val === '-' ||
          val === '\\' ||
          val === '' ||
          val === '(' ||
          val === ')'
        ) {
          flag = true;
        }
      });
      return flag;
    },
    checkIsHasChineseStr(str) {
      var Reg = /.*[\u4e00-\u9fa5]+.*/;
      if (Reg.test(str)) {
        return true;
      }
      return false;
    }
  },
  computed: {
    plate_license_1() {
      return (
        this.formData.num0 +
        this.formData.num1 +
        this.formData.num2 +
        this.formData.num3 +
        this.formData.num4 +
        this.formData.num5 +
        this.formData.num6
      );
    },
    plate_license_2() {
      return (
        this.formData.num0 +
        this.formData.num1 +
        this.formData.num2 +
        this.formData.num3 +
        this.formData.num4 +
        this.formData.num5 +
        this.formData.num6 +
        this.formData.num7
      );
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.van-tabs__line {
  background-color: #1989fa;
}
.plateNumber {
  height: auto;
  padding-top: 0.16rem;
}
.flex-items-center {
  display: flex;
  align-items: center;
}
.box-check {
  font-size: 0.14rem;
  height: 0.38rem;
}
/deep/.icon-class {
  font-size: 0.16rem;
  vertical-align: 0 !important;
}
.spot {
  &::after {
    content: '·';
  }
}
@-webkit-keyframes active {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes active {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
}
.wrap {
  background-color: #fff;
  border-radius: 0.5rem;
  // input输入框
  .num-box {
    display: flex;
    justify-content: space-between;
    padding: 0 0.1rem;
    align-items: center;
    margin-top: 0.1rem;
    & > div {
      width: 0.38rem;
      height: 0.38rem;
      border: 1px solid rgba(188, 188, 188, 0.5);
      border-radius: 5px;
      &.first {
        position: relative;
        text-align: center;
        line-height: 0.17rem;
        font-weight: 200;
        .input-wrap {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          &.active {
            z-index: 100;
          }
        }
        em {
          color: #979797;
          line-height: 1.7rem;
        }
        span {
          display: inline-block;
          width: 100%;
          height: 100%;
          color: #000;
          line-height: 1rem;
        }
      }
      &.active {
        border: 1px solid #4a90e2;
        &:after {
          border-bottom: 0.5rem solid #4a90e2;
        }
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #000;
        &.first {
          background-color: #9cbce2;
          color: #fff;
          text-indent: 0.4rem;
          border-radius: 0;
        }
      }
    }
  }

  .info {
    margin-top: 0.9rem;
    color: #000;
    text-align: left;
    img {
      width: 0.6rem;
      vertical-align: middle;
    }
  }
}
.first-word-wrap {
  background-color: #e0e0e0;
  .first-word {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.45rem;
    .word {
      box-sizing: border-box;
      width: 1rem;
      height: 1rem;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
      border-radius: 0.16rem;
      text-align: center;
      &.bordernone {
        border: none;
        box-shadow: none;
      }
      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 100%;
        background-color: #fff;
        color: #000;
        border-radius: 0.125rem;
      }
      img {
        width: 1.6rem;
      }
    }
    &:nth-last-of-type(1) {
      margin-bottom: 0rem;
    }
  }
}
.delete-btn {
  width: 0.56rem;
  padding-top: 0.04rem;
  height: 0.38rem;
  border-radius: 4px;
  background: white;
  line-height: 0.38rem;
  text-align: center;
  box-shadow: 0px 1px 0px 0px rgba(29, 29, 29, 0.25);
}
.keyboard-wrap {
  padding: 0.12rem 0.1rem;
  background-color: #ebebeb;
  .keyboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0.38rem;
    margin-bottom: 0.12rem;
    /deep/.van-button--normal {
      padding: 0;
      width: 0.48rem;
    }
    .van-button--default {
      background-color: white;
      box-shadow: 0px 1px 0px 0px rgba(29, 29, 29, 0.25);
      color: #333;
      width: 0.32rem;
      height: 0.38rem;
    }
    &.justify-space-between {
      .van-button + .van-button {
        margin-left: 0.04rem;
      }
    }
    span {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.125rem;
      &:active {
        background-color: #e4e4e4;
      }
      &.bordernone {
        border: none;
        box-shadow: none;
        background-color: #e0e0e0;
        &:active {
          background-color: #e0e0e0;
        }
      }
      &.delete {
        background-image: linear-gradient(to left, #25aae1, #1989fa, #25aae1);
        img {
          width: 1.15rem;
        }
      }
    }
  }
  .cancel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.5rem;
      height: 0.3rem;
      background-image: linear-gradient(to left, #25aae1, #1989fa, #25aae1);
      color: #fff;
      border-radius: 0.125rem;
    }
  }
  .cancel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.5rem;
      height: 0.3rem;
      background-image: linear-gradient(to left, #25aae1, #1989fa, #25aae1);
      color: #fff;
      border-radius: 0.125rem;
    }
  }
}
</style>
